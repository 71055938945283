<template>
  <div class="footer-gov-federal">
    <footer id="portal-footer" class="portal-footer">
            <div class="footer-wrapper p-5" style="margin:auto">
              <div class="govbr-logo"></div>
              <!--<div class="colunas-rodape">
                  <div class="coluna-menu">-->
              <ul class="list-navigation"><li class="plain dropdown-submenu"><a href="https://www.gov.br/icmbio/pt-br/servicos" class="plain">Serviços</a>
                    <ul class="submenu" style="">
                        <li>
    <a href="https://www.gov.br/pt-br/servicos/visitar-unidades-de-conservacao-federais" class="state-published">
                
                Agendamento de visitas em unidades de conservação federais
            </a>
    </li>

    <li>
    <a href="http://ava.icmbio.gov.br/" class="state-published">
                
                Ambiente Virtual de Aprendizagem - AVA
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/pt-br/servicos/solicitar-autorizacao-de-uso-de-imagem-em-unidades-de-conservacao-federais" class="state-published">
                
                Autorização de uso de imagem em unidades de conservação federais
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/pt-br/servicos/solicitar-autorizacao-de-eventos-nas-unidades-de-conservacao-federal" class="state-published">
                
                Autorização de eventos nas unidades de conservação federais
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/autorizacao-para-licenciamento-1" class="state-published">
                
                Autorização para Licenciamento
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/carta-de-servicos-1" class="state-published">
                
                Carta de Serviços
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/concessao-de-servicos-de-apoio-a-visitacao" class="state-published">
                
                Concessão de serviços de apoio à visitação
            </a>
    </li>

    <li class="dropdown-submenu">
    <a href="https://www.gov.br/icmbio/pt-br/servicos/seja-um-brigadista" class="state-published hasDropDown">
                
                Seja um Brigadista
            </a>

            <ul class="submenu navTree navTreeLevel1" style="display: block;">
                




    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/seja-um-brigadista/2021" class="state-published">
                
                2021
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/seja-um-brigadista/2020" class="state-published">
                
                2020
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/seja-um-brigadista/2019" class="state-published">
                
                2019
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/seja-um-brigadista/2018-1" class="state-published">
                
                2018
            </a>
    </li>




            </ul>
        
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/acoes-e-programas/acoes-socioambientais-e-consolidacao-territorial-em-ucs/programa-de-voluntariado-do-icmbio" class="state-published">
                
                Seja um Voluntário
            </a>
    </li>

    <li class="dropdown-submenu">
    <a href="https://www.gov.br/icmbio/pt-br/servicos/infracoes-ambientais" class="state-published hasDropDown">
                
                Infrações Ambientais
            </a>

            <ul class="submenu navTree navTreeLevel1" style="display: block;">
                




    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/infracoes-ambientais/areas-embargadas" class="state-published">
                
                Areas Embargadas
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/infracoes-ambientais/julgamento-de-autos-de-infracao" class="state-published">
                
                Julgamento de Autos de Infração
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/infracoes-ambientais/programa-de-conversao-de-multas-ambientais" class="state-published">
                
                Programa de Conversão de Multas Ambientais
            </a>
    </li>




            </ul>
        
    </li>

    <li class="dropdown-submenu">
    <a href="https://www.gov.br/icmbio/pt-br/servicos/compensacao-de-reserva-legal" class="state-published hasDropDown">
                
                Compensação de reserva legal
            </a>

            <ul class="submenu navTree navTreeLevel1" style="display: block;">
                




    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/compensacao-de-reserva-legal/amazonia" class="state-published">
                
                Amazônia
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/compensacao-de-reserva-legal/cerrado" class="state-published">
                
                Cerrado
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/compensacao-de-reserva-legal/pantanal" class="state-published">
                
                Pantanal
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/compensacao-de-reserva-legal/caatinga" class="state-published">
                
                Caatinga
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/compensacao-de-reserva-legal/mata-atlantica" class="state-published">
                
                Mata Atlântica
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/compensacao-de-reserva-legal/caatinga-1" class="state-published">
                
                Caatinga
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/compensacao-de-reserva-legal/pampa" class="state-published">
                
                Pampa
            </a>
    </li>




            </ul>
        
    </li>

    <li class="dropdown-submenu">
    <a href="https://www.gov.br/icmbio/pt-br/servicos/crie-sua-reserva" class="state-published hasDropDown">
                
                Crie sua Reserva
            </a>

            <ul class="submenu navTree navTreeLevel1" style="display: block;">
                




    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/crie-sua-reserva/livro-perguntas-e-respostas-sobre-rppn" class="state-published">
                
                Livro Perguntas e Respostas sobre RPPN
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/crie-sua-reserva/roteiro-para-criacao-de-rppn-federal" class="state-published">
                
                Roteiro para Criação de RPPN Federal
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/crie-sua-reserva/roteiro-metodologico-para-elaboracao-de-plano-de-manejo-para-rppn" class="state-published">
                
                Roteiro Metodológico para elaboração de Plano de Manejo para RPPN
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/crie-sua-reserva/formulario-do-roteiro-metodologico" class="state-published">
                
                Formulário do Roteiro Metodológico
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/crie-sua-reserva/sobre-rppn" class="state-published">
                
                Sobre RPPN
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/crie-sua-reserva/criacao-de-rppn" class="state-published">
                
                Criação de RPPN
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/crie-sua-reserva/plano-de-manejo-de-rppn" class="state-published">
                
                Plano de Manejo de RPPN
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/crie-sua-reserva/documentacao-necessaria-para-a-criacao-de-rppn" class="state-published">
                
                Documentação necessária para a criação de RPPN
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/crie-sua-reserva/legislacao" class="state-published">
                
                Legislação
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/crie-sua-reserva/perguntas-e-respostas-sobre-rppn" class="state-published">
                
                Perguntas e Respostas sobre RPPN
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/crie-sua-reserva/documentacao-necessaria-para-a-criacao-de-rppn-1" class="state-published">
                
                Documentação necessária para a criação de RPPN
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/crie-sua-reserva/anexoii-termo_de_compromisso-1.doc" class="state-published">
                
                Modelo do Termo de Compromisso
            </a>
    </li>

    <li>
    <a href="https://sistemas.icmbio.gov.br/simrppn/publico/" class="state-published">
                
                Lista das RPPN criadas
            </a>
    </li>

    <li>
    <a href="https://sistemas.icmbio.gov.br/simrppn/publico/consulta_publica/" class="state-published">
                
                Consulta Pública de RPPN
            </a>
    </li>

    <li>
    <a href="http://sistemas.icmbio.gov.br/simrppn/login/" class="state-published">
                
                Requerimento online
            </a>
    </li>




            </ul>
        
    </li>

    <li class="dropdown-submenu">
    <a href="https://www.gov.br/icmbio/pt-br/servicos/geoprocessamento" class="state-published hasDropDown">
                
                Geoprocessamento
            </a>

            <ul class="submenu navTree navTreeLevel1" style="display: block;">
                




    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/geoprocessamento/mapa-tematico-e-dados-geoestatisticos-das-unidades-de-conservacao-federais" class="state-published">
                
                Mapa Temático e Dados Geoestatísticos das Unidades de Conservação Federais
            </a>
    </li>




            </ul>
        
    </li>

    <li class="dropdown-submenu">
    <a href="https://www.gov.br/icmbio/pt-br/servicos/sistemas" class="state-published hasDropDown">
                
                Sistemas
            </a>

            <ul class="submenu navTree navTreeLevel1" style="display: block;">
                




    <li>
    <a href="https://www.icmbio.gov.br/cecav/canie.html" class="state-published">
                
                CANIE - Cadastro Nacional de Informações Espeleológicas
            </a>
    </li>

    <li>
    <a href="https://sicae.sisicmbio.icmbio.gov.br/usuario-externo/login/" class="state-published">
                
                SISFAMÍLIAS - Sistema de Informações das Famílias em Unidades de Conservação Federais
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/sistemas/sei-sistema-eletronico-de-informacoes" class="state-published">
                
                SEI! - Sistema Eletrônico de Informações
            </a>
    </li>

    <li>
    <a href="https://sisicmbio.icmbio.gov.br/" class="state-published">
                
                SIGE - Sistema Integrado de Gestão Estratégica
            </a>
    </li>

    <li>
    <a href="https://sicae.sisicmbio.icmbio.gov.br/" class="state-published">
                
                CIS - Catálogos de Imagens de Satélites
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/sistemas/sisbio-sistema-de-autorizacao-e-informacao-em-biodiversidade" class="state-published">
                
                SISBIO - Sistema de Autorização e Informação em Biodiversidade
            </a>
    </li>

    <li>
    <a href="https://sisicmbio.icmbio.gov.br/" class="state-published">
                
                SIGICMBio - Sistema de Gestão Estratégica
            </a>
    </li>

    <li>
    <a href="https://sisicmbio.icmbio.gov.br/" class="state-published">
                
                SCT - Sistema de Consolidação Territorial
            </a>
    </li>

    <li>
    <a href="https://sicae.sisicmbio.icmbio.gov.br/" class="state-published">
                
                SARR - Sistema de Arrecadação
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/sistemas/samge-sistema-de-analise-e-monitoramento-de-gestao" class="state-published">
                
                SAMGe - Sistema de Análise e Monitoramento de Gestão
            </a>
    </li>

    <li>
    <a href="https://compensacao.icmbio.gov.br/;jsessionid=KnK8ND-DcxkrqXEP8DEO6rYym_-fY715nZF2tWv0.svdf0161?0" class="state-published">
                
                SISCOMP - Sistema de Compensação
            </a>
    </li>




            </ul>
        
    </li>

    <li>
    <a href="http://qv.icmbio.gov.br/QvAJAXZfc/opendoc2.htm?document=painel_corporativo_6476.qvw&amp;host=Local&amp;anonymous=true" class="state-published">
                
                Painel Dinâmico
            </a>
    </li>

    <li class="dropdown-submenu">
    <a href="https://www.gov.br/icmbio/pt-br/servicos/termos-de-audiencias-de-conciliacao" class="state-published hasDropDown">
                
                Termos de audiências de conciliação
            </a>

            <ul class="submenu navTree navTreeLevel1" style="display: block;">
                




    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/termos-de-audiencias-de-conciliacao/ac-rio-branco" class="state-published">
                
                AC - Rio Branco
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/termos-de-audiencias-de-conciliacao/al-maceio" class="state-published">
                
                Al - Maceió
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/termos-de-audiencias-de-conciliacao/am-manaus" class="state-published">
                
                AM - Manaus
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/termos-de-audiencias-de-conciliacao/ap-macapa" class="state-published">
                
                AP - Macapá
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/termos-de-audiencias-de-conciliacao/ba-salvador" class="state-published">
                
                BA - Salvador
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/termos-de-audiencias-de-conciliacao/ce-fortaleza" class="state-published">
                
                CE - Fortaleza
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/termos-de-audiencias-de-conciliacao/df-brasilia" class="state-published">
                
                DF - Brasília
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/termos-de-audiencias-de-conciliacao/es-vitoria" class="state-published">
                
                ES - Vitória
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/termos-de-audiencias-de-conciliacao/go-goiania" class="state-published">
                
                GO - Goiânia
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/termos-de-audiencias-de-conciliacao/ma-sao-luis" class="state-published">
                
                MA - São Luís
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/termos-de-audiencias-de-conciliacao/mg-belo-horizonte" class="state-published">
                
                MG - Belo Horizonte
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/termos-de-audiencias-de-conciliacao/ms-campo-grande" class="state-published">
                
                MS - Campo Grande
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/termos-de-audiencias-de-conciliacao/mt-cuiaba" class="state-published">
                
                MT - Cuiabá
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/termos-de-audiencias-de-conciliacao/pa-belem" class="state-published">
                
                PA - Belém
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/termos-de-audiencias-de-conciliacao/pa-santarem" class="state-published">
                
                PA - Santarém
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/termos-de-audiencias-de-conciliacao/pb-joao-pessoa" class="state-published">
                
                PB - João Pessoa
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/termos-de-audiencias-de-conciliacao/pe-recife" class="state-published">
                
                PE - Recife
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/termos-de-audiencias-de-conciliacao/pi-teresina" class="state-published">
                
                PI - Teresina
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/termos-de-audiencias-de-conciliacao/pr-curitiba" class="state-published">
                
                PR - Curitiba
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/termos-de-audiencias-de-conciliacao/rj-rio-de-janeiro" class="state-published">
                
                RJ - Rio de Janeiro
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/termos-de-audiencias-de-conciliacao/rn-natal" class="state-published">
                
                RN - Natal
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/termos-de-audiencias-de-conciliacao/ro-porto-velho" class="state-published">
                
                RO - Porto Velho
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/termos-de-audiencias-de-conciliacao/rr-boa-vista" class="state-published">
                
                RR - Boa Vista
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/termos-de-audiencias-de-conciliacao/rs-porto-alegre" class="state-published">
                
                RS - Porto Alegre
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/termos-de-audiencias-de-conciliacao/sc-florianopolis" class="state-published">
                
                SC - Florianópolis
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/termos-de-audiencias-de-conciliacao/se-aracaju" class="state-published">
                
                SE - Aracaju
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/termos-de-audiencias-de-conciliacao/sp-sao-paulo" class="state-published">
                
                SP - São Paulo
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/termos-de-audiencias-de-conciliacao/to-palmas" class="state-published">
                
                TO - Palmas
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/termos-de-audiencias-de-conciliacao/legislacao-aplicavel-a-conciliacao-ambiental" class="state-published">
                
                Legislação aplicável à Conciliação Ambiental
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/termos-de-audiencias-de-conciliacao/informacoes-sobre-o-agendamento" class="state-published">
                
                Informações sobre o agendamento
            </a>
    </li>




            </ul>
        
    </li>

    <li class="dropdown-submenu">
    <a href="https://www.gov.br/icmbio/pt-br/servicos/audiencias-de-conciliacao-agendadas" class="state-published hasDropDown">
                
                Audiências de Conciliação Agendadas
            </a>

            <ul class="submenu navTree navTreeLevel1" style="display: block;">
                




    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/audiencias-de-conciliacao-agendadas/ac-rio-branco" class="state-published">
                
                AC - Rio Branco
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/audiencias-de-conciliacao-agendadas/al-maceio" class="state-published">
                
                AL - Maceió
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/audiencias-de-conciliacao-agendadas/am-manaus" class="state-published">
                
                AM - Manaus
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/audiencias-de-conciliacao-agendadas/ap-macapa" class="state-published">
                
                AP - Macapá
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/audiencias-de-conciliacao-agendadas/ba-salvador" class="state-published">
                
                BA - Salvador
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/audiencias-de-conciliacao-agendadas/ce-fortaleza" class="state-published">
                
                CE - Fortaleza
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/audiencias-de-conciliacao-agendadas/df-brasilia" class="state-published">
                
                DF - Brasília
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/audiencias-de-conciliacao-agendadas/es-vitoria" class="state-published">
                
                ES - Vitória
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/audiencias-de-conciliacao-agendadas/go-goiania" class="state-published">
                
                GO - Goiânia
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/audiencias-de-conciliacao-agendadas/ma-sao-luis" class="state-published">
                
                MA - São Luís
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/audiencias-de-conciliacao-agendadas/mg-belo-horizonte" class="state-published">
                
                MG - Belo Horizonte
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/audiencias-de-conciliacao-agendadas/ms-campo-grande" class="state-published">
                
                MS - Campo Grande
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/audiencias-de-conciliacao-agendadas/mt-cuiaba" class="state-published">
                
                MT - Cuiabá
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/audiencias-de-conciliacao-agendadas/pa-belem" class="state-published">
                
                PA - Belém
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/audiencias-de-conciliacao-agendadas/pa-santarem" class="state-published">
                
                PA - Santarém
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/audiencias-de-conciliacao-agendadas/pb-joao-pessoa" class="state-published">
                
                PB - João Pessoa
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/audiencias-de-conciliacao-agendadas/pe-recife" class="state-published">
                
                PE - Recife
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/audiencias-de-conciliacao-agendadas/pi-teresina" class="state-published">
                
                PI - Teresina
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/audiencias-de-conciliacao-agendadas/pr-curitiba" class="state-published">
                
                PR - Curitiba
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/audiencias-de-conciliacao-agendadas/rj-rio-de-janeiro" class="state-published">
                
                RJ - Rio de Janeiro
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/audiencias-de-conciliacao-agendadas/rn-natal" class="state-published">
                
                RN - Natal
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/audiencias-de-conciliacao-agendadas/ro-porto-velho" class="state-published">
                
                RO - Porto Velho
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/audiencias-de-conciliacao-agendadas/rr-boa-vista" class="state-published">
                
                RR - Boa Vista
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/audiencias-de-conciliacao-agendadas/rs-porto-alegre" class="state-published">
                
                RS - Porto Alegre
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/audiencias-de-conciliacao-agendadas/sc-florianopolis" class="state-published">
                
                SC - Florianópolis
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/audiencias-de-conciliacao-agendadas/se-aracaju" class="state-published">
                
                SE - Aracaju
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/audiencias-de-conciliacao-agendadas/sp-sao-paulo" class="state-published">
                
                SP - São Paulo
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/audiencias-de-conciliacao-agendadas/to-palmas" class="state-published">
                
                TO - Palmas
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/audiencias-de-conciliacao-agendadas/legislacao-aplicavel-a-conciliacao-ambiental" class="state-published">
                
                Legislação aplicável à Conciliação Ambiental
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/audiencias-de-conciliacao-agendadas/informacoes-sobre-o-agendamento" class="state-published">
                
                Informações sobre o agendamento
            </a>
    </li>




            </ul>
        
    </li>
                    </ul>
                </li><li class="plain dropdown-submenu"><a href="https://www.gov.br/icmbio/pt-br/assuntos" class="plain">Assuntos</a>
                    <ul class="submenu" style="">
                        <li class="dropdown-submenu">
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/noticias" class="state-published hasDropDown">
                
                Notícias
            </a>

            <ul class="submenu navTree navTreeLevel1" style="display: block;">
                




    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/noticias/ultimas-noticias" class="state-published">
                
                Últimas Notícias
            </a>
    </li>




            </ul>
        
    </li>

    <li class="dropdown-submenu">
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/visite-as-unidades-de-conservacao-federais" class="state-published hasDropDown">
                
                Visite as Unidades de Conservação Federais
            </a>

            <ul class="submenu navTree navTreeLevel1" style="display: block;">
                




    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/visite-as-unidades-de-conservacao-federais/concessao-nos-parques" class="state-published">
                
                Concessão nos parques
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/parnaanavilhanas/guia-do-visitante.html" class="state-published">
                
                Parque Nacional de Anavilhanas (AM)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/visitacao1/unidades-abertas-a-visitacao/213-parque-nacional-de-brasilia.html" class="state-published">
                
                Parque Nacional de Brasília (DF)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/parnaguimaraes" class="state-published">
                
                Parque Nacional da Chapada dos Guimarães (MT)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/visitacao1/unidades-abertas-a-visitacao/192-parque-nacional-marinho-fernando-de-noronha.html" class="state-published">
                
                Parque Nacional Marinho de Fernando de Noronha (PE)
            </a>
    </li>

    <li>
    <a href="http://www.parquedoitatiaia.tur.br/" class="state-published">
                
                Parque Nacional de Itatiaia (RJ)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/parnalencoismaranhenses/guia-do-visitante.html" class="state-published">
                
                Parque Nacional dos Lençóis Maranhenses (MA)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/parnaserradosorgaos/guia-do-visitante.html" class="state-published">
                
                Parque Nacional Serra dos Órgãos (RJ)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/parnatijuca/guia-do-visitante.html" class="state-published">
                
                Parque Nacional da Tijuca (RJ)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/visitacao1/unidades-abertas-a-visitacao/7552-parque-nacional-grande-sertao-veredas.html" class="state-published">
                
                Parque Nacional Grande Sertão Veredas (MG/BA)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/visitacao1/unidades-abertas-a-visitacao/9053-parque-nacional-do-pau-brasil" class="state-published">
                
                Parque Nacional do Pau Brasil (BA)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/parnaserradabocaina/guia-do-visitante.html" class="state-published">
                
                Parque Nacional da Serra da Bocaina (RJ/SP)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/visitacao1/unidades-abertas-a-visitacao/204-parque-nacional-das-emas" class="state-published">
                
                Parque Nacional da das Emas (GO)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/visitacao1/unidades-abertas-a-visitacao/4060-floresta-nacional-de-canela" class="state-published">
                
                Floresta Nacional de Canela (RS)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/parnasaojoaquim/guia-do-visitante.html" class="state-published">
                
                Parque Nacional de São Joaquim (SC)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/visitacao1/unidades-abertas-a-visitacao/194-parque-nacional-do-monte-pascoal" class="state-published">
                
                Parque Nacional Histórico do Monte Pascoal (BA)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/visitacao1/unidades-abertas-a-visitacao/199-parque-nacional-da-serra-da-capivara" class="state-published">
                
                Parque Nacional da Serra da Capivara (PI)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/flonatapajos/guia-do-visitante" class="state-published">
                
                Floresta Nacional do Tapajós (PA)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/visitacao1/unidades-abertas-a-visitacao/9411-area-de-protecao-ambiental-delta-do-parnaiba" class="state-published">
                
                Área de Proteção Ambiental Delta do Parnaíba (PI/MA/CE)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/visitacao1/unidades-abertas-a-visitacao/9591-parque-nacional-do-virua" class="state-published">
                
                Parque Nacioal do Viruá (RR)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/visitacao1/unidades-abertas-a-visitacao/189-parque-nacional-do-jau" class="state-published">
                
                Parque Nacional do Jaú (AM)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/parnaaparadosdaserra/guia-do-visitante.html" class="state-published">
                
                Parque Nacional de Aparados da Serra (RS)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/visitacao1/unidades-abertas-a-visitacao/9396-parque-nacional-da-chapada-da-diamantina" class="state-published">
                
                Parque Nacional da Chapada Diamantina (BA)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/parnachapadadosveadeiros/guia-do-visitante.html" class="state-published">
                
                Parque Nacional da Chapada dos Veadeiros (GO)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/parnaiguacu/guia-do-visitante.html" class="state-published">
                
                Parque Nacional do Iguaçu (PR)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/visitacao1/unidades-abertas-a-visitacao/190-parque-nacional-de-jericoacoara.html" class="state-published">
                
                Parque Nacional de Jericoacoara (CE)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/parnaserradocipo/guia-do-visitante.html" class="state-published">
                
                Parque Nacional da Serra do Cipó (MG)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/visitacao1/unidades-abertas-a-visitacao/730-parque-nacional-da-serra-geral.html" class="state-published">
                
                Parque Nacional da Serra Geral (RS)
            </a>
    </li>

    <li>
    <a href="https://hom-pu.serpro.gov.br/icmbio/pt-br/parnaubajara/guia-do-visitante.html" class="state-published">
                
                Parque Nacional de Ubajara (CE)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/parnaubajara/guia-do-visitante.html" class="state-published">
                
                Parque Nacional de Ubajara (CE)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/visitacao1/unidades-abertas-a-visitacao/8642-o-parque-nacional-cavernas-do-peruacu" class="state-published">
                
                Parque Nacional Cavernas do Peruaçu (MG)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/parnajurubatiba/guia-do-visitante.html" class="state-published">
                
                Parque Nacional da Restinga de Jurubatiba (RJ)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/visitacao1/unidades-abertas-a-visitacao/2590-floresta-nacional-de-passa-quatro" class="state-published">
                
                Floresta Nacional de Passa Quatro (MG)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/visitacao1/unidades-abertas-a-visitacao/4039-floresta-de-silvania" class="state-published">
                
                Floresta Nacional de Silvânia (GO)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/visitacao1/unidades-abertas-a-visitacao/9362-parque-nacional-da-lagoa-do-peixe" class="state-published">
                
                Parque Nacional da Lagoa do Peixe (RS)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/parnaabrolhos/guia-do-visitante.html" class="state-published">
                
                Parque Nacional Marinho de Abrolhos (BA)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/visitacao1/unidades-abertas-a-visitacao/208-parque-nacional-das-sete-cidades" class="state-published">
                
                Parque Nacional de Sete Cidades (PI)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/visitacao1/unidades-abertas-a-visitacao/9360-floresta-nacional-de-sao-francisco-de-paula" class="state-published">
                
                Floresta Nacional de São Francisco de Paula (RS)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/visitacao1/unidades-abertas-a-visitacao/9398-floresta-nacional-de-brasilia" class="state-published">
                
                Floresta Nacional de Brasília (DF)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/visitacao1/unidades-abertas-a-visitacao/9463-parque-nacional-da-serra-do-gandarela" class="state-published">
                
                Parque Nacional da Serra do Gandarela (MG)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/unidadesdeconservacao/biomas-brasileiros/mais-info/10213-alcatrazes" class="state-published">
                
                Refúgio de Alcatrazes (SP)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/visitacao1/unidades-abertas-a-visitacao/11294-floresta-nacional-flona-do-amapa" class="state-published">
                
                Floresta Nacional (Flona) do Amapá (AP)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/apacostadoscorais/" class="state-published">
                
                Área de Proteção Ambiental Costa dos Corais (PE)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/visitacao1/unidades-abertas-a-visitacao/9491-area-de-protecao-ambiental-das-nascentes-do-rio-vermelho" class="state-published">
                
                Área de Proteção Ambiental das Nascentes do Rio Vermelho (GO)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/visitacao1/unidades-abertas-a-visitacao/3919-area-de-protecao-ambiental-de-cairucu" class="state-published">
                
                Área de Proteção Ambiental de Cairuçu (RJ)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/visitacao1/unidades-abertas-a-visitacao/4061-floresta-nacional-de-carajas" class="state-published">
                
                Floresta Nacional de Carajás (PA)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/visitacao1/unidades-abertas-a-visitacao/2680-floresta-nacional-de-ipanema" class="state-published">
                
                Floresta Nacional de Ipanema (RJ)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/visitacao1/unidades-abertas-a-visitacao/3923-flona-de-lorena" class="state-published">
                
                Floresta Nacional de Lorena (SP)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/visitacao1/unidades-abertas-a-visitacao/4059-flona-de-palmares" class="state-published">
                
                Floresta Nacional de Palmares (PI)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/visitacao1/unidades-abertas-a-visitacao/2592-floresta-nacional-sao-francisco-de-paula" class="state-published">
                
                Floresta Nacional São Francisco de Paula (RS)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/visitacao1/unidades-abertas-a-visitacao/4239-parna-ilha-grande" class="state-published">
                
                Parna Ilha Grande (MS)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/visitacao1/unidades-abertas-a-visitacao/200-parque-nacional-da-amazonia" class="state-published">
                
                Parque Nacional da Amazônia (PA)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/visitacao1/unidades-abertas-a-visitacao/198-parque-nacional-da-serra-da-canastra" class="state-published">
                
                Parque Nacional da Serra da Canastra (MG)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/visitacao1/unidades-abertas-a-visitacao/731-parque-nacional-da-serra-do-itajai" class="state-published">
                
                Parque Nacional da Serra do Itajaí (SC)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/visitacao1/unidades-abertas-a-visitacao/204-parque-nacional-das-emas" class="state-published">
                
                Parque Nacional das Emas (GO)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/visitacao1/unidades-abertas-a-visitacao/2587-parque-nacional-de-boa-nova" class="state-published">
                
                Parque Nacional de Boa Nova (BA)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/visitacao1/unidades-abertas-a-visitacao/201-parque-nacional-do-caparao" class="state-published">
                
                Parque Nacional do Caparaó (MG)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/visitacao1/unidades-abertas-a-visitacao/732-parque-nacional-do-catimbau" class="state-published">
                
                Parque Nacional do Catimbau (PE)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/visitacao1/unidades-abertas-a-visitacao/195-parque-nacional-do-pantanal-matogrossense" class="state-published">
                
                Parque Nacional do Pantanal Matogrossense (MA)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/visitacao1/unidades-abertas-a-visitacao/209-parque-nacional-do-superagui" class="state-published">
                
                Parque Nacional do Superagui (PR)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/visitacao1/unidades-abertas-a-visitacao/9591-parque-nacional-do-virua" class="state-published">
                
                Parque Nacional do Viruá (RR)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/visitacao1/unidades-abertas-a-visitacao/194-parque-nacional-do-monte-pascoal" class="state-published">
                
                Parque Nacional e Histórico do Monte Pascoal (BA)
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/visitacao1/unidades-abertas-a-visitacao/7552-parque-nacional-grande-sertao-veredas" class="state-published">
                
                Parque Nacional Grande Sertão Veredas
            </a>
    </li>

    <li>
    <a href="https://www.icmbio.gov.br/portal/visitacao1/unidades-abertas-a-visitacao/4057-reserva-biologica-uniao" class="state-published">
                
                Reserva Biológica União (RJ)
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/visite-as-unidades-de-conservacao-federais/revisilhadoslobos" class="state-published">
                
                Refúgio de Vida Silvestre da Ilha dos Lobos (RS)
            </a>
    </li>




            </ul>
        
    </li>

    <li class="dropdown-submenu">
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/biodiversidade" class="state-published hasDropDown">
                
                Biodiversidade
            </a>

            <ul class="submenu navTree navTreeLevel1" style="display: block;">
                




    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/biodiversidade/pan" class="state-published">
                
                Planos de Ação Nacional para Conservação de Espécies Ameaçadas de Extinção - PAN
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/biodiversidade/planos-de-reducao-de-impacto" class="state-published">
                
                Planos de Redução de Impacto
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/biodiversidade/unidade-de-conservacao" class="state-published">
                
                Unidade de Conservação
            </a>
    </li>




            </ul>
        
    </li>

    <li class="dropdown-submenu">
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/centros-de-pesquisa" class="state-published hasDropDown">
                
                Centros de Pesquisa
            </a>

            <ul class="submenu navTree navTreeLevel1" style="display: block;">
                




    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/centros-de-pesquisa/aves" class="state-published">
                
                Aves
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/centros-de-pesquisa/biodiversidade-amazonica" class="state-published">
                
                Biodiversidade Amazônica
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/centros-de-pesquisa/mamiferos-carnivoros" class="state-published">
                
                Mamíferos carnívoros
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/centros-de-pesquisa/cecav" class="state-published">
                
                Cecav
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/centros-de-pesquisa/avaliacao-da-biodiversidade-e-cerrado" class="state-published">
                
                Avaliação da Biodiversidade e Cerrado
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/centros-de-pesquisa/mamiferos-aquaticos-cma" class="state-published">
                
                Mamíferos Aquáticos - CMA
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/centros-de-pesquisa/biodiversidade-marinha" class="state-published">
                
                Biodiversidade Marinha
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/centros-de-pesquisa/peixes-continentais" class="state-published">
                
                Peixes Continentais
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/centros-de-pesquisa/povos-e-comunidades-tradicionais" class="state-published">
                
                Povos e Comunidades Tradicionais
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/centros-de-pesquisa/primatas" class="state-published">
                
                Primatas
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/centros-de-pesquisa/repteis-e-anfibios" class="state-published">
                
                Répteis e Anfíbios
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/centros-de-pesquisa/tartarugas-marinhas-e-biodiversidade-marinha-do-leste" class="state-published">
                
                Tartarugas Marinhas e Biodiversidade Marinha do Leste
            </a>
    </li>




            </ul>
        
    </li>

    <li class="dropdown-submenu">
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/criacao-de-unidades-de-conservacao" class="state-published hasDropDown">
                
                Criação de Unidades de Conservação
            </a>

            <ul class="submenu navTree navTreeLevel1" style="display: block;">
                




    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/criacao-de-unidades-de-conservacao/legislacao" class="state-published">
                
                Legislação
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/criacao-de-unidades-de-conservacao/rito-legal-para-a-criacao-de-unidades-de-conservacao" class="state-published">
                
                Rito Legal para a Criação de Unidades de Conservação
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/criacao-de-unidades-de-conservacao/efetividade-da-gestao-de-ucs" class="state-published">
                
                Efetividade da Gestão de UCs
            </a>
    </li>




            </ul>
        
    </li>

    <li class="dropdown-submenu">
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/mosaicos-e-corredores-ecologicos" class="state-published hasDropDown">
                
                Mosaicos e Corredores Ecológicos
            </a>

            <ul class="submenu navTree navTreeLevel1" style="display: block;">
                




    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/mosaicos-e-corredores-ecologicos/projeto-jalapao" class="state-published">
                
                Projeto Jalapão
            </a>
    </li>




            </ul>
        
    </li>

    <li class="dropdown-submenu">
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/protecao" class="state-published hasDropDown">
                
                Proteção
            </a>

            <ul class="submenu navTree navTreeLevel1" style="display: block;">
                




    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/protecao/fiscalizacao-ambiental" class="state-published">
                
                Fiscalização Ambiental
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/protecao/emergencias-ambientais" class="state-published">
                
                Emergências Ambientais
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/protecao/monitoramento-ambiental" class="state-published">
                
                Monitoramento Ambiental
            </a>
    </li>




            </ul>
        
    </li>

    <li class="dropdown-submenu">
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/visitacao" class="state-published hasDropDown">
                
                Visitação
            </a>

            <ul class="submenu navTree navTreeLevel1" style="display: block;">
                




    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/visite-as-unidades-de-conservacao-federais" class="state-published">
                
                Visite as Unidades de Conservação Federais
            </a>
    </li>




            </ul>
        
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/consolidacao-territorial" class="state-published">
                
                Consolidação Territorial
            </a>
    </li>

    <li class="dropdown-submenu">
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/gestao-socioambiental-1" class="state-published hasDropDown">
                
                Gestão Socioambiental
            </a>

            <ul class="submenu navTree navTreeLevel1" style="display: block;">
                




    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/gestao-socioambiental-1/2019" class="state-published">
                
                2019
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/gestao-socioambiental-1/2018" class="state-published">
                
                2018
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/gestao-socioambiental-1/2017" class="state-published">
                
                2017
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/gestao-socioambiental-1/gestao-participativa" class="state-published">
                
                Gestão Participativa
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/gestao-socioambiental-1/gestao-de-conflitos" class="state-published">
                
                Gestão de Conflitos
            </a>
    </li>




            </ul>
        
    </li>

    <li class="dropdown-submenu">
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/populacoes-tradicionais" class="state-published hasDropDown">
                
                Populações Tradicionais
            </a>

            <ul class="submenu navTree navTreeLevel1" style="display: block;">
                




    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/populacoes-tradicionais/producao-e-uso-sustentavel" class="state-published">
                
                Produção e Uso Sustentável
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/populacoes-tradicionais/politicas-e-comunidades-tradicionais" class="state-published">
                
                Políticas e Comunidades Tradicionais
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/populacoes-tradicionais/comissao-das-reservas-extrativistas-federais-conarex" class="state-published">
                
                Comissão das Reservas Extrativistas Federais - Conarex
            </a>
    </li>




            </ul>
        
    </li>

    <li class="dropdown-submenu">
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/manejo-para-conservacao" class="state-published hasDropDown">
                
                Manejo para Conservação
            </a>

            <ul class="submenu navTree navTreeLevel1" style="display: block;">
                




    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/manejo-para-conservacao/coordenacao-geral-de-manejo-e-conservacao" class="state-published">
                
                Coordenação Geral de Manejo e Conservação
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/manejo-para-conservacao/coordenacao-de-avaliacao-do-estado-de-conservacao-da-biodiversidade" class="state-published">
                
                Coordenação de Avaliação do Estado de Conservação da Biodiversidade
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/manejo-para-conservacao/coordenacao-de-analise-e-prognostico-de-risco-a-biodiversidade" class="state-published">
                
                Coordenação de Análise e Prognóstico de Risco à Biodiversidade
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/manejo-para-conservacao/coordenacao-de-planos-de-acao-de-especies-ameacadas-de-extincao" class="state-published">
                
                Coordenação de Planos de Ação de Espécies Ameaçadas de Extinção
            </a>
    </li>




            </ul>
        
    </li>

    <li class="dropdown-submenu">
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/pesquisa" class="state-published hasDropDown">
                
                Pesquisa
            </a>

            <ul class="submenu navTree navTreeLevel1" style="display: block;">
                




    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/pesquisa/iniciacao-cientifica" class="state-published">
                
                Iniciação Científica
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/pesquisa/projetos-apoiados" class="state-published">
                
                Projetos Apoiados
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/pesquisa/como-participar" class="state-published">
                
                Como participar
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/pesquisa/nossos-pesquisadores" class="state-published">
                
                Nossos pesquisadores
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/pesquisa/venha-pesquisar-conosco" class="state-published">
                
                Venha Pesquisar Conosco
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/pesquisa/seminarios-de-pesquisa" class="state-published">
                
                Seminários de Pesquisa
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/pesquisa/revistas-e-periodicos" class="state-published">
                
                Revistas e Periódicos
            </a>
    </li>




            </ul>
        
    </li>

    <li class="dropdown-submenu">
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/programas-e-projetos" class="state-published hasDropDown">
                
                Programas e Projetos
            </a>

            <ul class="submenu navTree navTreeLevel1" style="display: block;">
                




    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/programas-e-projetos/projeto-cenarios-estrategia-de-conservacao-da-savana-metalofila-da-floresta-nacional-de-carajas" class="state-published">
                
                Projeto Cenários: Estratégia de Conservação da Savana Metalófila da Floresta Nacional de Carajás
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/programas-e-projetos/projeto-corredor-ecologico-regiao-do-jalapao" class="state-published">
                
                Projeto Corredor Ecológico Região do Jalapão
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/programas-e-projetos/projeto-praticas-inovadoras" class="state-published">
                
                Projeto Práticas Inovadoras
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/programas-e-projetos/projetos-de-conservacao-de-especies" class="state-published">
                
                Projetos de Conservação de Espécies
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/programas-e-projetos/programas-de-cativeiro" class="state-published">
                
                Programas de Cativeiro
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/programas-e-projetos/projeto-nacional-de-acoes-integradas-publico-privadas-para-biodiversidade-probio-ii" class="state-published">
                
                Projeto Nacional de Ações Integradas Público-Privadas para Biodiversidade-Probio II
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/programas-e-projetos/projeto-manguezais-do-brasil" class="state-published">
                
                Projeto Manguezais do Brasil
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/programas-e-projetos/portal-da-biodiversidade" class="state-published">
                
                Portal da Biodiversidade
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/programas-e-projetos/fauna-brasileira" class="state-published">
                
                Fauna Brasileira
            </a>
    </li>




            </ul>
        
    </li>

    <li class="dropdown-submenu">
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/acoes-e-programas" class="state-published hasDropDown">
                
                Ações e Programas
            </a>

            <ul class="submenu navTree navTreeLevel1" style="display: block;">
                




    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/acoes-e-programas/acoes-socioambientais-e-consolidacao-territorial-em-ucs" class="state-published">
                
                Ações Socioambientais e Consolidação Territorial em UCs
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/acoes-e-programas/pesquisa-avaliacao-e-monitoramento-da-biodiversidade-1" class="state-published">
                
                Pesquisa, Avaliação e Monitoramento da Biodiversidade
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/acoes-e-programas/planejamento-administracao-e-logistica-1" class="state-published">
                
                Planejamento, Administração e Logística
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/acoes-e-programas/criacao-e-manejo-de-unidades-de-conservacao-1" class="state-published">
                
                Criação e Manejo de Unidades de Conservação
            </a>
    </li>




            </ul>
        
    </li>

    <li class="dropdown-submenu">
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/participacao-social" class="state-published hasDropDown">
                
                Participação social
            </a>

            <ul class="submenu navTree navTreeLevel1" style="display: block;">
                




    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/participacao-social/lista-de-consultas-publicas" class="state-published">
                
                Lista de Consultas Públicas
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/participacao-social/consultas-publicas-para-criacao-de-unidades-de-conservacao-federais" class="state-published">
                
                Consultas Públicas para Criação de Unidades de Conservação federais
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/participacao-social/normativas-planos-de-manejo-em-consulta-publica" class="state-published">
                
                Normativas/Planos de Manejo em Consulta Pública
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/participacao-social/educacao-ambiental" class="state-published">
                
                Educação Ambiental
            </a>
    </li>




            </ul>
        
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/compensacao-ambiental" class="state-published">
                
                Compensação ambiental
            </a>
    </li>

    <li class="dropdown-submenu">
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/monitoramento" class="state-published hasDropDown">
                
                Monitoramento
            </a>

            <ul class="submenu navTree navTreeLevel1" style="display: block;">
                




    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/monitoramento/copy_of_principios-e-diretrizes" class="state-published">
                
                Princípios e Diretrizes
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/monitoramento/gestao-da-informacao" class="state-published">
                
                Gestão da Informação
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/monitoramento/programas-de-monitoramento-da-biodiversidade-em-ucs" class="state-published">
                
                Programas de Monitoramento da Biodiversidade em UCs
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/monitoramento/artigos-de-monitoramento" class="state-published">
                
                Artigos de Monitoramento
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/monitoramento/conteudo" class="state-published">
                
                Conteúdo
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/monitoramento/mapa-das-ucs" class="state-published">
                
                Mapa das UCs
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/monitoramento/capacitacao" class="state-published">
                
                Capacitação
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/pesquisa/como-participar" class="state-published">
                
                Como participar
            </a>
    </li>




            </ul>
        
    </li>

    <li class="dropdown-submenu">
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/adote-um-parque" class="state-published hasDropDown">
                
                Adote um Parque
            </a>

            <ul class="submenu navTree navTreeLevel1" style="display: block;">
                




    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/adote-um-parque/arquivos" class="state-published">
                
                Arquivos
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/adote-um-parque/list-of-parks" class="state-published">
                
                List of Parks
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/assuntos/adote-um-parque/lista-de-parques" class="state-published">
                
                Lista de Parques
            </a>
    </li>




            </ul>
        
    </li>
                    </ul>
                </li><li class="plain dropdown-submenu"><a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao" class="plain">Acesso à informação</a>
                    <ul class="submenu" style="">
                        <li class="dropdown-submenu">
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/institucional" class="state-published hasDropDown">
                
                Institucional
            </a>

            <ul class="submenu navTree navTreeLevel1" style="display: block;">
                




    <li>
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/institucional/o-instituto" class="state-published">
                
                O Instituto
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/institucional/estrutura-1" class="state-published">
                
                Estrutura
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/institucional/quem-e-quem-1" class="state-published">
                
                Quem é quem
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/institucional/gerencias-regionais" class="state-published">
                
                Gerências Regionais
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/institucional/academia-nacional-da-biodiversidade" class="state-published">
                
                Academia Nacional da Biodiversidade
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/institucional/procuradoria-federal-especializada" class="state-published">
                
                Procuradoria Federal Especializada
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/institucional/onde-estamos" class="state-published">
                
                Onde estamos
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/institucional/gestao-estrategica" class="state-published">
                
                Gestão Estratégica
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/institucional/missao" class="state-published">
                
                Missão
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/institucional/comite-gestor" class="state-published">
                
                Comitê Gestor
            </a>
    </li>




            </ul>
        
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/agenda-de-autoridades" class="state-published">
                
                Agenda de autoridades
            </a>
    </li>

    <li class="dropdown-submenu">
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/auditorias" class="state-published hasDropDown">
                
                Auditorias
            </a>

            <ul class="submenu navTree navTreeLevel1" style="display: block;">
                




    <li>
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/auditorias/processos-de-contas-anuais" class="state-published">
                
                Processos de Contas Anuais
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/auditorias/relatorios-de-gestao" class="state-published">
                
                Relatórios de Gestão
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/auditorias/auditoria-interna" class="state-published">
                
                Auditoria Interna
            </a>
    </li>




            </ul>
        
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/convenios-e-transferencias" class="state-published">
                
                Convênios e transferências
            </a>
    </li>

    <li class="dropdown-submenu">
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/programa-de-integridade-do-icmbio" class="state-published hasDropDown">
                
                Programa de Integridade do ICMBio
            </a>

            <ul class="submenu navTree navTreeLevel1" style="display: block;">
                




    <li>
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/programa-de-integridade-do-icmbio/conheca-o-integra-o-programa-de-integridade-do-icmbio" class="state-published">
                
                Conheça o Integra+, o Programa de Integridade do ICMBio
            </a>
    </li>




            </ul>
        
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/receitas-e-despesas" class="state-published">
                
                Receitas e despesas
            </a>
    </li>

    <li class="dropdown-submenu">
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/licitacoes-e-contratos" class="state-published hasDropDown">
                
                Licitações e Contratos
            </a>

            <ul class="submenu navTree navTreeLevel1" style="display: block;">
                




    <li>
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/licitacoes-e-contratos/licitacoes" class="state-published">
                
                Licitações
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/licitacoes-e-contratos/chamamento-publico" class="state-published">
                
                Chamamento Público
            </a>
    </li>




            </ul>
        
    </li>

    <li class="dropdown-submenu">
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/editais-diversos" class="state-published hasDropDown">
                
                Editais Diversos
            </a>

            <ul class="submenu navTree navTreeLevel1" style="display: block;">
                




    <li>
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/editais-diversos/editais-diversos-2021" class="state-published">
                
                Editais Diversos - 2021
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/editais-diversos/editais-diversos-2020" class="state-published">
                
                Editais Diversos - 2020
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/editais-diversos/editais-diversos-2019" class="state-published">
                
                Editais Diversos - 2019
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/editais-diversos/editais-diversos-2018" class="state-published">
                
                Editais Diversos - 2018
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/editais-diversos/editais-diversos-2017" class="state-published">
                
                Editais Diversos - 2017
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/editais-diversos/editais-diversos-2016" class="state-published">
                
                Editais Diversos - 2016
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/editais-diversos/editais-diversos-2015" class="state-published">
                
                Editais Diversos - 2015
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/editais-diversos/editais-diversos-2014" class="state-published">
                
                Editais Diversos - 2014
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/editais-diversos/editais-diversos-2013" class="state-published">
                
                Editais Diversos - 2013
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/editais-diversos/editais-diversos-2012" class="state-published">
                
                Editais Diversos - 2012
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/editais-diversos/editais-diversos-2011" class="state-published">
                
                Editais Diversos - 2011
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/editais-diversos/editais-diversos-2010" class="state-published">
                
                Editais Diversos - 2010
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/editais-diversos/editais-diversos-2004-a-2008" class="state-published">
                
                Editais Diversos - 2004 a 2008
            </a>
    </li>




            </ul>
        
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/servidores" class="state-published">
                
                Servidores
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/terceirizados" class="state-published">
                
                Terceirizados
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/informacoes-classificadas-1" class="state-published">
                
                Informações Classificadas
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/sic-servico-de-informacao-ao-cidadao-1" class="state-published">
                
                SIC - Serviço de informação ao cidadão
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/perguntas-frequentes" class="state-published">
                
                Perguntas Frequentes
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/dados-abertos" class="state-published">
                
                Dados Abertos
            </a>
    </li>

    <li class="dropdown-submenu">
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/legislacao" class="state-published hasDropDown">
                
                Legislação
            </a>

            <ul class="submenu navTree navTreeLevel1" style="display: block;">
                




    <li>
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/legislacao/leis" class="state-published">
                
                Leis
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/legislacao/decretos" class="state-published">
                
                Decretos
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/legislacao/instrucoes-normativas" class="state-published">
                
                Instruções Normativas
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/legislacao/portarias" class="state-published">
                
                Portarias
            </a>
    </li>




            </ul>
        
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/acesso-a-informacao/encarregado-pelo-tratamento-de-dados-pessoais-dpo" class="state-published">
                
                Encarregado pelo tratamento de dados pessoais - DPO
            </a>
    </li>
                    </ul>
                </li><li class="plain dropdown-submenu"><a href="https://www.gov.br/icmbio/pt-br/composicao" class="plain">Composição</a>
                    <ul class="submenu" style="">
                        <li>
    <a href="https://www.gov.br/icmbio/pt-br/composicao/presidencia" class="state-published">
                
                Presidência
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/composicao/diretoria-de-planejamento-administracao-e-logistica" class="state-published">
                
                Diretoria de Planejamento, Administração e Logística
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/composicao/diretoria-de-criacao-e-manejo-de-unidades-de-conservacao" class="state-published">
                
                Diretoria de Criação e Manejo de Unidades de Conservação
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/composicao/diretoria-de-acoes-socioambientais-e-consolidacao-territorial-em-ucs" class="state-published">
                
                Diretoria de Ações Socioambientais e Consolidação Territorial em UCs
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/composicao/diretoria-de-pesquisa-avaliacao-e-monitoramento-da-biodiversidade" class="state-published">
                
                Diretoria de Pesquisa, Avaliação e Monitoramento da Biodiversidade
            </a>
    </li>
                    </ul>
                </li><li class="plain dropdown-submenu"><a href="https://www.gov.br/icmbio/pt-br/centrais-de-conteudo" class="plain">Centrais de Conteúdo</a>
                    <ul class="submenu" style="">
                        <li>
    <a href="https://www.gov.br/pt-br/servicos/solicitar-autorizacao-de-uso-de-imagem-em-unidades-de-conservacao-federais" class="state-published">
                
                Imagens
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/centrais-de-conteudo/videos" class="state-published">
                
                Vídeos
            </a>
    </li>

    <li class="dropdown-submenu">
    <a href="https://www.gov.br/icmbio/pt-br/centrais-de-conteudo/publicacoes" class="state-published hasDropDown">
                
                Publicações
            </a>

            <ul class="submenu navTree navTreeLevel1" style="display: block;">
                




    <li>
    <a href="https://www.gov.br/icmbio/pt-br/centrais-de-conteudo/publicacoes/atlas-1" class="state-published">
                
                Atlas
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/centrais-de-conteudo/publicacoes/documentos" class="state-published">
                
                Documentos
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/centrais-de-conteudo/publicacoes/legislacao" class="state-published">
                
                Legislação
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/centrais-de-conteudo/publicacoes/publicacoes-mma" class="state-published">
                
                Publicações MMA
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/centrais-de-conteudo/publicacoes/planos" class="state-published">
                
                Planos
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/centrais-de-conteudo/publicacoes/publicacoes-diversas" class="state-published">
                
                Publicações Diversas
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/centrais-de-conteudo/publicacoes/revistas-cientificas" class="state-published">
                
                Revistas Científicas
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/centrais-de-conteudo/publicacoes/relatorios" class="state-published">
                
                Relatórios
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/centrais-de-conteudo/publicacoes/roteiros" class="state-published">
                
                Roteiros
            </a>
    </li>




            </ul>
        
    </li>
                    </ul>
                </li><li class="plain dropdown-submenu"><a href="https://www.gov.br/icmbio/pt-br/canais_atendimento" class="plain">Canais de Atendimento</a>
                    <ul class="submenu" style="">
                        <li>
    <a href="https://www.gov.br/icmbio/pt-br/canais_atendimento/imprensa" class="state-published">
                
                Imprensa
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/canais_atendimento/ouvidoria-1" class="state-published">
                
                Ouvidoria
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/canais_atendimento/biodiversa" class="state-published">
                
                Biodiversa
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/canais_atendimento/marca-do-icmbio" class="state-published">
                
                Marca do ICMBio
            </a>
    </li>

    <li>
    <a href="https://www.gov.br/icmbio/pt-br/servicos/sistemas/sei-sistema-eletronico-de-informacoes" class="state-published">
                
                Processo Eletrônico
            </a>
    </li>
                    </ul>
                </li><li class="plain dropdown-submenu"><a href="https://www.gov.br/icmbio/pt-br/redes-sociais-canais" class="plain">Redes Sociais/Canais</a>
                    <ul class="submenu" style="">
                        <li>
    <a href="https://www.facebook.com/ICMBio/photos/a.145941932140301/3124454730955658/?type=3" class="state-published">
                
                Facebook
            </a>
    </li>

    <li>
    <a href="https://www.instagram.com/icmbio/?hl=pt-br" class="state-published">
                
                Instagram
            </a>
    </li>

    <li>
    <a href="https://www.youtube.com/user/canalicmbio" class="state-published">
                
                Youtube
            </a>
    </li>
    <li>
            <a href="https://twitter.com/ICMBio" class="state-published">
                Twitter
            </a>
                      </li>
                    </ul>
                </li></ul>
              <div class="redes-e-logos">
                <div class="redes-sociais">
                  <div class="titulo">Redes sociais</div>
                <ul class="portal-redes">
                
                    <li class="portalredes-twitter portalredes-item">
                        <a href="https://www.twitter.com/ICMBio">Twitter</a>
                    </li>
                
                    <li class="portalredes-youtube portalredes-item">
                        <a href="https://www.youtube.com//user/canalicmbio">YouTube</a>
                    </li>
                
                    <li class="portalredes-facebook portalredes-item">
                        <a href="https://www.facebook.com//ICMBio/photos/a.145941932140301/3124454730955658/?type=3">Facebook</a>
                    </li>
                    <li class="portalredes-instagram portalredes-item">
                        <a href="https://www.instagram.com/icmbio">Instagram</a>
                    </li>
                </ul>
            </div>
              <div id="footer-brasil">
                <div id="wrapper-footer-brasil">
                        <a class="logo-acesso-footer" href="http://www.acessoainformacao.gov.br/" alt="Acesso à informação" title="Acesso à informação"></a>
                        <!--
                        <a class="logo-governo-federal" href="http://www.brasil.gov.br/" alt="Governo Federal" title="Governo Federal"></a>
                        -->
                </div>
                </div>
              </div>
            </div>

            <div class="texto-copyright">
        <span omit-tag="">Todo o conteúdo deste site está publicado sob a licença</span> <a rel="license" href="https://creativecommons.org/licenses/by-nd/3.0/deed.pt_BR">Creative Commons Atribuição-SemDerivações 3.0 Não Adaptada</a>.
      </div>
    </footer>
  </div>
</template>

<script>
export default {
        mounted(){
                let head = document.getElementsByTagName('head')[0]
                const govtheme = document.createElement("script")
                govtheme.setAttribute("src","/govbrtheme-footer.js")
                govtheme.setAttribute("id","govbrtheme-footer")
                govtheme.async = true
                head.appendChild(govtheme)
        }
};
</script>